@import url(https://fonts.googleapis.com/css?family=Montserrat:500,800);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #1f1f1f;
  /* font-family: "Neue Montreal", "Helvetica", "Arial", sans-serif; */
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #fff;
  font-weight: 500;
}

a {
  color: #fff;
  text-decoration: none;
}
.logo {
  width: 10%;
  height: 98%;
  left: 0.5%;
  position: fixed;
  top: 1%;
}

.ws {
  position: fixed;
  top: 1%;
  width: 5%;
  height: 98%;
  right: 0.5%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.content {
  position: relative;
  margin: 1% auto auto 12%;
  width: 80%;
}
p {
  font-size: 3.95833vw;
  color: #fff;

  line-height: 1.2;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}
.downloads {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}
.download {
  width: 400px;
  border: 2px solid #fff;
  box-sizing: border-box;

  font-size: 24px;
  margin-bottom: 32px;
  margin-right: 32px;
}
.header {
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  box-sizing: border-box;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.week {
  padding: 8px;
  border-right: 2px solid #fff;
  flex-grow: 1;
}
.date {
  padding: 8px;
  flex-grow: 1;
  text-align: right;
}
.topics {
  padding: 8px;
  /* padding-top: 16px;
  padding-bottom: 16px; */
  border-bottom: 2px solid #fff;
}
.dl {
  padding: 8px;
  text-align: right;
  padding-bottom: 0px;
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dl:last-child {
  padding-bottom: 24px;
}
button {
  border-radius: 0px;
  padding: 4px 8px 4px 8px;
  background-color: #fff;
  font-size: 18px;
  margin-left: 12px;
  background-color: #1f1f1f;
  border-color: #fff;
  color: #fff;
  border-width: 2px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.25, 0.26, 0.45, 0.94);
}
button:hover {
  background-color: #fff;
  border-color: #fff;
  color: #1f1f1f;
  border-width: 2px;
  transition: all 0.4s cubic-bezier(0.25, 0.26, 0.45, 0.94);
}

